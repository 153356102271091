// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "wysiwyg-content.scss";

.work-images {

  .current-images img {
    max-width: 150px;
  }
  .current-images {
    .image-wrapper {
      max-width: 170px;
      border: solid 1px #999999;
      border-radius: 10px;
      padding: 10px;
      margin: 5px;
      display:inline-block;
    }
    .image-wrapper.featured {
      border: solid 2px yellow;
    }
  }
}

body {
  font-family: 'Lato';
}

.fa-btn {
  margin-right: 6px;
}

.admin-image-list {
  ul {
    display:flex;
    justify-content: space-around;
    align-items: flex-start;
    list-style: none;
    margin:0;
    padding:0;
    flex-wrap: wrap;
  }
  li {
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(.5,0,.5,1);
    max-width: 210px;
    padding:5px;
    margin:0 auto 20px auto;
    border: solid 1px rgba(255,255,255,1);
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.16);
    img {
      max-width:200px;
      max-height:200px;
      display:block;
      border-radius: 4px 4px 2px 2px;
      margin-left:auto;
      margin-right:auto;
    }
    div.edit-wrapper {
      float:right;
      margin-left:5px;
      position: relative;
      top: -10px;
      right: -10px;
      height:0;
    }
    a.edit {
      background-color: white;
      border-radius: 15px;
      border: solid 1px #AAAAFF;
      padding: 3px;
      width: 28px;
      height: 28px;
      i.fa-btn {
        margin:4px;
      }
    }
    a.edit:hover {
      background-color: #AAAAFF;
      color:white;
    }
  }
  li.sibling-selected {
    border: solid 1px #04EAEA;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.56);
    -moz-box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.56);
    -webkit-box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.56);
  }
  .work-series-name {
    display:block;
    font-size:1.2em;
  }
  .work-title {
    display:block;
    font-size:1.1em;
  }
  .work-photo-view {
    display:block;
    font-size:.9em;
  }
}

.work-title {
  font-style:italic;
}